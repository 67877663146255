import "@fontsource/montserrat/200.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/700.css";
import merge from "lodash/merge";
import { Helmet } from "react-helmet";
import { ThemeUIProvider } from "theme-ui";
import baseTheme from "../../../theme";

export default function WinesFromSpainTop100Layout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeUIProvider theme={useTheme()}>
      <Helmet>
        <style type="text/css">
          {`
        // Mantine defaults to antialiased font smoothing, which looks terrible
        :root {
          --mantine-webkit-font-smoothing: subpixel-antialiased;
        }
        html, body {
          -webkit-font-smoothing: subpixel-antialiased;
        }
    `}
        </style>
      </Helmet>
      {children}
    </ThemeUIProvider>
  );
}

function useTheme() {
  return {
    ...baseTheme,
    colors: merge({}, baseTheme.colors, {
      primary: "#dd250f",
      secondary: "#222",
      onSecondary: "#fff",
      accent: "#fed40b",
      text: "#222",
      overlay: "rgba(0,0,0, 0.4)",
      overlayLight: "rgba(0,0,0, 0.1)",
      overlayPrimary: "rgba(221,37,15, 0.9)",
      variety: {
        red: { border: "#dd250f", text: "#FFCFC9" },
        white: { border: "#ffd51a", text: "#736215" },
        rose: { border: "#dd250f", text: "#FFCFC9" },
        // white: '#a6ce38',
      },
      topBar: { background: "#333F48", color: "#fff" },
    }),

    fonts: merge({}, baseTheme.fonts, {
      heading: "Montserrat, sans-serif",
      body: "'Open Sans', sans-serif",
    }),

    fontWeights: merge({}, baseTheme.fontWeights, {
      heading: "700",
      semititle: "200",
      body: "400",
    }),

    text: merge({}, baseTheme.text, {
      // The region heading of "Explore by Producer".
      groupLabel: {
        textTransform: "uppercase",
        fontFamily: "body",
        fontSize: "small",
        fontWeight: "bold",
      },
      // The producer name in each grid item.
      gridLabel: {
        textTransform: "uppercase",
        fontFamily: "body",
        fontSize: "small",
        fontWeight: "body",
      },
      largeGridLabel: {
        textTransform: "uppercase",
        fontFamily: "body",
        fontSize: "18px",
        fontWeight: "semi-bold",
      },
    }),

    button: merge({}, baseTheme.button, {
      primary: {
        paddingX: 3,
        paddingY: 2,
        borderRadius: "round",
        ":hover": {
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.secondary}`,
        },
      },
      secondary: {
        paddingX: 3,
        paddingY: 2,
        borderRadius: "round",
        color: "primary",
        boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.primary}`,
        ":hover": {
          color: "secondary",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.secondary}`,
        },
        "&[disabled]": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
        "&[disabled]:hover": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
        backgroundColor: "transparent",
      },
      outline: {
        paddingX: 3,
        paddingY: 2,
        borderRadius: "round",
        color: "primary",
        boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.primary}`,
        ":hover": {
          color: "secondary",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.secondary}`,
        },
        "&[disabled]": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
        "&[disabled]:hover": {
          color: "lightText",
          boxShadow: (theme) => `inset 0 0 0 2px ${theme.colors.lightText}`,
        },
      },
    }),

    topBar: {
      color: "topBar.color",
      backgroundColor: "topBar.background",
      height: 41,
    },

    filter: merge({}, baseTheme.filter, {
      largest: {
        input: { borderRadius: "round", marginRight: 3 },
        wrapper: {
          borderRadius: "round",
          border: 3,
          borderColor: "primary",
          boxShadow: (theme) =>
            `0px 8px 10px -10px ${theme.colors.primary}, 0px 2px 10px -5px ${theme.colors.secondary}, ${theme.shadows.overlay}`,
          ":hover, :focus-within": {
            borderColor: "primary",
            color: "primary",
            boxShadow: (theme) =>
              `0px 5px 20px -10px ${theme.colors.primary}, 0px 5px 20px -10px ${theme.colors.secondary}, ${theme.shadows.dialog}`,
          },
        },
      },
    }),
  };
}
