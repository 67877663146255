import {
  Box,
  Col,
  Container,
  Flex,
  Label,
} from "@bottlebooks/gatsby-theme-base/src";
import { FragmentType, graphql, useFragment } from "~/gql";
import ContentRow from "../ContentRow";
import Section from "../Section/Section";

const fragment = graphql(`
  fragment WinesFromSpainTop100Section on RegisteredProduct {
    aromaChart: customFieldValue(key: "aromaChart") {
      ... on TextFieldValue {
        value
      }
    }
    winesFromSpainMedalImage: customFieldValue(
      key: "winesFromSpainMedalImage"
    ) {
      ... on TextFieldValue {
        value
      }
    }
    winesFromSpainMedal: customFieldValue(key: "winesFromSpainMedal") {
      ... on TextFieldValue {
        value
      }
    }
  }
`);

export default function WinesFromSpainTop100Section({
  data,
}: {
  data: FragmentType<typeof fragment>;
}) {
  const registeredProduct = useFragment(fragment, data);
  return (
    <Container>
      <ContentRow>
        <Col flex sx={{ marginY: 3, paddingX: 4 }}>
          <Flex direction="column" gap={2}>
            <Box>
              <Label sx={{ marginBottom: 2 }}>Wines from Spain Top 100</Label>
              <Flex gap={2}>
                <img src={registeredProduct.winesFromSpainMedalImage?.value} />
                <p>{registeredProduct.winesFromSpainMedal?.value}</p>
              </Flex>
            </Box>
            <img
              style={{ width: "100%" }}
              src={registeredProduct.aromaChart?.value}
            />
          </Flex>
        </Col>
      </ContentRow>
    </Container>
  );
}
