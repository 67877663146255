/** @jsxImportSource theme-ui */
import { Col, Container, Row, Title } from "@bottlebooks/gatsby-theme-base/src";
import { Trans } from "@lingui/macro";
import { FragmentType, graphql, useFragment } from "~/gql";
import useLink from "../../useLink";
import ContentRow from "../ContentRow";
import ProductActions from "../Product/ProductActions.next";
import ProductSections from "../ProductPage/ProductSections.next";
import RelatedProductsSection from "../ProductPage/RelatedProductsSection.next";
import Related from "../Related/Related";
import Section from "../Section/Section";
// import SEO from "../seo";
import React from "react";
import BrandAddressSection from "../Brand/BrandAddressSection.next";
import BrandDetailsSection from "../Brand/BrandDetailsSection.next";
import Image from "../Image/Image";
import EventProductPageHeader from "./EventProductPageHeader.next";
import WinesFromSpainTop100Section from "./WinesFromSpainTop100Section.next";

export const fragment = graphql(`
  fragment EventProductPage_RegisteredProduct on RegisteredProduct {
    ...ProductSections_RegisteredProduct
    ...ProductActions_RegisteredProduct
    ...EventProductPageHeaderRegisteredProduct
    exhibitor: registration {
      exhibitorId: companyId
      profile {
        ...BrandBanner_RegistrationProfile
      }
    }
    producer {
      producerId: brandId
      ...BrandDetailsSection_PresentedBrand
      profile {
        name
        mainImage {
          publicId
        }
        ...BrandDetailsSection
        ...BrandAddressSection_Profile
      }
      products {
        nodes {
          ...RelatedProductsSection
        }
      }
    }
    ...WinesFromSpainTop100Section
  }
`);

export default function EventProductPage({
  product,
  data,
  eventId,
  otherProducts,
  otherProductsName,
  relatedProducts,
  previous,
  next,
}: {
  product: any;
  data: FragmentType<typeof fragment>;
  eventId: string;
  otherProducts: any;
  otherProductsName: any;
  relatedProducts: any;
  previous: any;
  next: any;
}) {
  const link = useLink();
  const hasOtherProducts = Boolean(otherProducts && otherProducts.length);
  const hasRelatedProducts = Boolean(relatedProducts && relatedProducts.length);
  const hasRelated = hasOtherProducts || hasRelatedProducts;
  const registeredProduct = useFragment(fragment, data);
  const exhibitor = registeredProduct.exhibitor;
  const producer = registeredProduct.producer;
  if (!producer) throw new Error("No producer.");
  const producerProfile = producer?.profile;
  if (!producerProfile) throw new Error("No producer profile.");
  const standPath = exhibitor
    ? link.exhibitor(exhibitor)
    : link.producer(producer);
  if (!standPath) throw new Error("No stand path.");
  const name = otherProductsName;
  // const page = {
  //   title: `${product.fullName}`,
  //   path: location.href,
  //   image: product.bottleImage?.fixed?.src,
  //   description: product.description,
  // };
  return (
    <React.Fragment>
      {/* <SEO page={page} /> */}
      <Container>
        <EventProductPageHeader
          product={registeredProduct}
          standPath={standPath}
        />
      </Container>

      <Container>
        <ContentRow>
          <Col sx={{ paddingY: 3 }} flex>
            <ProductActions data={product} />
          </Col>
        </ContentRow>
      </Container>
      <WinesFromSpainTop100Section data={registeredProduct} />
      <ProductSections data={registeredProduct} />
      <Container
        fluid
        sx={{
          backgroundColor: "light",
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Container>
          <ContentRow>
            <Col sx={{ marginBottom: 3 }}>
              <Title>{producer.profile?.name}</Title>
            </Col>
          </ContentRow>
          {/* {producer.profile?.mainImage?.publicId ? (
            <ContentRow>
              <Image
                publicId={producer.profile?.mainImage?.publicId}
                layout="fixed"
                // sizes={producer.profile?.mainImage?.sizes}
                options={{
                  width: 770,
                  crop: "fit",
                  background: "#fff0",
                  quality: "auto:best",
                }}
                sx={{
                  display: ["block", null, "none"],
                  maxWidth: "100%",
                  objectFit: "contain",
                  objectPosition: "center center",
                }}
              />

              <Image
                publicId={producer.profile?.mainImage?.publicId}
                layout="fixed"
                // sizes={producer.profile?.mainImage?.sizes}
                options={{
                  width: 770,
                  height: 385,
                  crop: "fill",
                  background: "#fff0",
                  quality: "auto:best",
                }}
                sx={{
                  display: ["none", null, "block"],
                }}
              />
            </ContentRow>
          ) : null} */}

          <Row
            sx={{
              paddingX: [null, `${100 / 12}%`], // 1 col spacing from medium width up.
            }}
          >
            <BrandDetailsSection
              data={producer.profile}
              presentedBrandsData={[producer]}
            />
          </Row>
        </Container>
      </Container>
      <BrandAddressSection data={producerProfile} sx={{ marginY: 0 }} />

      {hasRelated && (
        <Section sx={{ paddingY: 4, paddingBottom: 5 }}>
          {hasOtherProducts && (
            <RelatedProductsSection
              registeredProducts={otherProducts}
              exhibitor={exhibitor}
              title={
                name ? (
                  <Trans>More from {name}</Trans>
                ) : (
                  <Trans>Other products</Trans>
                )
              }
              link={
                <Related.Link to={standPath}>
                  <Trans>See profile →</Trans>
                </Related.Link>
              }
              sx={{ paddingY: 4 }}
            />
          )}

          {hasRelatedProducts && (
            <RelatedProductsSection
              registeredProducts={relatedProducts}
              exhibitor={exhibitor}
              title={<Trans>Related products</Trans>}
              link={
                <Related.Link to={standPath}>
                  <Trans>See all</Trans>
                </Related.Link>
              }
              sx={{ paddingY: 4 }}
            />
          )}
        </Section>
      )}
    </React.Fragment>
  );
}
